var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-overlay",
    {
      attrs: {
        footer: {
          text: "Need help?",
          linkTitle: "Contact support",
          link: "mailto:support@xinfin.org",
        },
        "show-overlay": _vm.onManage,
        title: _vm.overlayTitle,
        close: _vm.close,
        "content-size": "large",
      },
    },
    [
      _vm.isTransfer
        ? _c("transfer", {
            ref: "transfer",
            attrs: {
              transfer: _vm.transfer,
              "manage-domain-handler": _vm.manageDomainHandler,
            },
          })
        : _vm._e(),
      _vm.isRenew
        ? _c("renew", {
            attrs: {
              "get-rent-price": _vm.getRentPrice,
              "host-name": _vm.hostName,
              renew: _vm.renew,
              "no-funds-for-renewal-fees": _vm.noFundsForRenewalFees,
              "get-total-renew-fee-only": _vm.getTotalRenewFeeOnly,
              "loading-renew": _vm.loadingRenew,
            },
          })
        : _vm._e(),
      _vm.isManageMulticoin
        ? _c("manage-multicoins", {
            attrs: {
              "set-multicoin": _vm.setMulticoin,
              multicoin: _vm.multicoin,
            },
          })
        : _vm._e(),
      _vm.isManageTxtRecord
        ? _c("manage-txt-records", {
            attrs: {
              "set-text-records": _vm.setTextRecords,
              "text-records": _vm.textRecords,
            },
          })
        : _vm._e(),
      _vm.isManageUpload
        ? _c("manage-upload-website", {
            attrs: {
              "setting-ipfs": _vm.settingIpfs,
              "set-ipfs": _vm.setIpfs,
              "upload-file": _vm.uploadFile,
              "uploaded-hash": _vm.uploadedHash,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }